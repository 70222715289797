import React from 'react';
import { Field } from 'react-final-form';
import { PRICE_CHANGE_TYPES, DESTINATION_TYPES } from 'shared/constants/ORDER';
import { Error, NumberInput } from '..';
import { DiscountIcon, MarkupIcon } from '../../Order/OrderIcons';
import Styled from './styles';

const ICONS = {
  [DESTINATION_TYPES.DISCOUNT]: DiscountIcon,
  [DESTINATION_TYPES.MARKUP]: MarkupIcon,
};

const LABELS = {
  [DESTINATION_TYPES.DISCOUNT]: 'Скидка',
  [DESTINATION_TYPES.MARKUP]: 'Наценка',
};

interface DiscountInputProps {
  meta: any;
  input: any;
  destination: number;
  max: number;
  fixedError?: boolean;
  currency?: string;
  customIcons?: any;
}

const DiscountInput = ({
  meta,
  input,
  destination,
  max,
  fixedError = false,
  customIcons,
  currency,
}: DiscountInputProps) => {
  const Icon = customIcons ? customIcons[destination] : ICONS[destination];

  return (
    <Styled.Container>
      <Styled.Wrapper meta={meta}>
        <Styled.Input>
          <NumberInput
            float
            label={
              <>
                {LABELS[destination]}
                <Styled.ArrowIcon>
                  <Icon />
                </Styled.ArrowIcon>
              </>
            }
            max={max}
            {...input}
            meta={meta}
            withoutError
          />
        </Styled.Input>
        <Field name="discount_type">
          {({ input: inputSelect }) => (
            <Styled.SelectWrapper>
              <Styled.Select {...inputSelect}>
                <option value={PRICE_CHANGE_TYPES.NUMBER}>{currency || '₽'}</option>
                <option value={PRICE_CHANGE_TYPES.PERCENTAGE}>%</option>
              </Styled.Select>
            </Styled.SelectWrapper>
          )}
        </Field>
      </Styled.Wrapper>
      <Error meta={meta} fixedError={fixedError} />
    </Styled.Container>
  );
};

export default DiscountInput;
