import { createFactory } from '@withease/factories';
import { createEvent, createStore, sample } from 'effector';
import { Router } from 'next/router';

/**
 * Все поля пангинации указаны как string или string[] потому, что  в противном случае появляется ошибка
 * при сравнивание типов Router[query] и кастомного {}, например при прокидывании аргументов в event.
 * TS выдает ошибку при том что парметрам задается определенный тип, при помощи утверждения типа через 'as'
 */
export type PagePaginationQuery = Router['query'] & {
  search?: string;
  limit?: string;
  page?: string | number;
  search_string?: string;
  location?: string;
  place_id_in?: string;
  location_id_in?: string;
  manager__in?: string;
  legal__in?: string;
  acquiring__in?: string;
  email__in?: string;
  company_id?: string;
};

export const createPagesMountStates = createFactory(() => {
  const pageMounted = createEvent<PagePaginationQuery>();

  const $query = createStore<PagePaginationQuery>({});
  const $loading = createStore<boolean>(true);

  sample({
    clock: pageMounted,
    target: $query,
  });

  return {
    pageMounted,
    $query,
    $loading,
  };
});
