import styled, { css } from 'styled-components';
import BREAKPOINTS from 'shared/constants/BREAKPOINTS';

interface ContainerProps {
  active?: boolean;
  isOpen?: boolean;
  disabled?: boolean;
}

export default {
  Container: styled.div<ContainerProps>`
    position: relative;
    height: ${(props) => (props.active ? '48px' : '40px')};
    width: ${(props) => (props.active ? '184px' : '40px')};
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.2s ease-in-out;
    background-color: ${(props) =>
      props.isOpen || props.active ? 'rgba(255, 218, 0, 0.24)' : 'transparent'};
    outline: none;
    border: 0;
    text-align: left;
    border-radius: ${({ active }) => (active ? '8px' : '50%')};

    & > img {
      width: 14px;
      max-width: none;
      user-select: none;
    }

    ${(props) =>
      !props.disabled &&
      css`
        &:hover {
          background-color: #f5f5f5;
          cursor: pointer;
        }

        &:hover::after {
          content: '';
          position: absolute;
          height: 10px;
          width: 100%;
          display: block;
          bottom: -10px;
        }
      `}

    ${(props) =>
      props.disabled &&
      css`
        &:hover {
          cursor: not-allowed;
        }
      `}

    @media (max-width: ${BREAKPOINTS.XL}) {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 40px;
      height: 40px;
      border-radius: 8px;

      ${({ active }) =>
        active &&
        css`
          width: 208px;
          min-height: 48px;
          background-color: #fff6c2;
          opacity: 1;
        `}
    }

    @media (max-width: ${BREAKPOINTS.MD}) {
      ${({ active, isOpen }) =>
        active &&
        css`
          width: 40px;
          min-height: 40px;
          background-color: #fff6c2;
          opacity: 1;

          &:hover:after,
          &:after {
            content: '';
            position: absolute;
            top: 30px;
            left: 0;
            display: ${isOpen ? 'block' : 'none'};
            width: 100%;
            height: 20px;
            background-color: #fff6c2;
          }
        `}
    }
  `,

  FormWrapper: styled.div`
    position: absolute;
    width: 400px;
    top: 44px;
    right: 0;
    box-shadow: 0px 16px 40px rgba(0, 0, 0, 0.08);
    border-radius: 8px;
    background: #f5f5f5;

    @media (max-width: ${BREAKPOINTS.MD}) {
      position: fixed;
      top: 56px;
      width: 100%;
      border-radius: 0 0 8px 8px;
      z-index: 2;
    }
  `,

  Overlay: styled.div`
    display: none;
    position: fixed;
    z-index: 1;
    top: 56px;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.24);
    pointer-events: none;

    @media (max-width: ${BREAKPOINTS.MD}) {
      display: block;
    }
  `,

  Dropdown: styled.div`
    position: absolute;
    min-width: 160px;
    top: 48px;
    right: 0;
    padding: 8px 0;
    background-color: #ffffff;
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.12);
    border-radius: 8px;

    @media (max-width: ${BREAKPOINTS.MD}) {
      display: none;
    }
  `,

  DropdownOption: styled.div<{ danger?: boolean }>`
    padding: 0 24px;
    height: 40px;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    font-size: 14px;
    transition: all 0.2s ease-in-out;
    cursor: pointer;
    user-select: none;

    &:hover {
      background-color: #f5f5f5;
    }

    color: ${({ danger }) => danger && '#EB5757'};
  `,

  Info: styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    box-sizing: border-box;
    padding: 0 16px;

    @media (max-width: ${BREAKPOINTS.SM}) {
      justify-content: center;
      padding: 0;
    }
  `,

  InfoInner: styled.div`
    display: flex;
    flex-direction: column;
  `,

  Name: styled.div`
    font-size: 16px;
    color: #000;
    max-width: 130px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;

    @media (max-width: ${BREAKPOINTS.MD}) {
      display: none;
    }
  `,

  Phone: styled.div`
    font-size: 12px;
    line-height: 16px;
    color: rgba(0, 0, 0, 0.64);
    white-space: nowrap;
    max-width: 100%;
    overflow: hidden;

    @media (max-width: ${BREAKPOINTS.MD}) {
      display: none;
    }
  `,

  Arrow: styled.div`
    width: 12px;
    height: 6px;
    background-image: url('/static/CallContext/arrow.svg');
    background-repeat: no-repeat;
  `,
  HistoryIcon: styled.span`
    display: flex;
    align-items: center;
    margin-right: 8px;

    & > svg {
      width: 12px;
      height: 12px;
      opacity: 0.4;
    }
  `,

  Footer: styled.div`
    position: relative;
    z-index: 2;
    height: 40px;
    background-color: #f5f5f5;
    border-radius: 0px 0px 8px 0px;
    display: flex;
    align-items: center;
    justify-content: center;
  `,

  FooterTitle: styled.div`
    font-size: 14px;
    margin-right: 8px;
    max-width: 100px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  `,

  FooterText: styled.div`
    color: #585858;
    font-size: 14px;
  `,

  MobileInfo: styled.div`
    position: fixed;
    top: 49px;
    left: 0;
    width: 100%;
    z-index: 1;
    font-size: 12px;
    line-height: 16px;
    background: #fff6c2;
    padding: 4px 14px;
    border-bottom: 1px solid #e1e1e1;

    @media (min-width: ${BREAKPOINTS.MD}) {
      display: none;
    }
  `,

  MobilePhone: styled.span`
    margin-left: 8px;
    color: rgba(0, 0, 0, 0.64);
  `,
};
