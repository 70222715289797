import AntdDatePicker from 'antd/lib/date-picker';
import locale from 'antd/lib/date-picker/locale/ru_RU';
import classnames from 'classnames';
import dayjs from 'dayjs';
import localeData from 'dayjs/plugin/localeData';
import head from 'lodash/head';
import isString from 'lodash/isString';
import last from 'lodash/last';
import React, { Component } from 'react';
import DATE_FORMATS from 'shared/constants/DATE_FORMATS';
import IDS from 'shared/constants/IDS';
import withCheckIsMobile from 'shared/lib/withCheckIsMobile';
import CalendarIcon from '../../../../static/icons/calendar.svg';
import CloseIcon from '../../../../static/icons/cancel-alt.svg';
import Button from '../../Button';
import Label from '../LabelNew';
import Styled from './styles';
import 'dayjs/locale/ru';

const { RangePicker } = AntdDatePicker;

const disabledDate = (current) => {
  if (current < dayjs().startOf('day')) {
    return true;
  }

  return false;
};

dayjs.extend(localeData);
dayjs.locale('ru');

export interface PickerProps {
  handleForm?: any;
  onChange: Function;
  saveDate?: ({ gte, lte }: { gte: string; lte: string }) => void;
  withoutOutdated?: boolean;
  withMobile?: boolean;
  isMobile?: boolean;
  size?: 'large' | 'small';
  placeholder?: [string, string];
  value: string | string[] | any[];
  queryName?: {
    gte: string;
    lte: string;
  };
  useStubTime?: boolean;
}

interface PickerState {
  isOpen: boolean;
}

class RangeDateTimePicker extends Component<PickerProps, PickerState> {
  static defaultProps = {
    size: 'large',
    placeholder: '',
    withoutOutdated: false,
    withMobile: false,
    isMobile: false,
    saveDate: null,
  };

  pickerContainer: any;

  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
    };

    this.pickerContainer = null;
  }

  componentDidMount() {
    this.pickerContainer = document.getElementById(IDS.POPUP_CONTAINER);
    document.getElementById(IDS.APP_LAYOUT)?.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount() {
    document.getElementById(IDS.APP_LAYOUT)?.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll = () => {
    this.hideDatePicker();
  };

  handleChange = (event: any, dateString: string[]) => {
    const { handleForm, onChange, saveDate, queryName, useStubTime } = this.props;
    const timeGTEStub = 'T00:00:00';
    const timeLTEStub = 'T23:59:59';
    const [from, to] = dateString;

    let fromValue =
      typeof from === 'string' && from.length > 0
        ? dayjs(from, DATE_FORMATS.DATE_DOTS).format(DATE_FORMATS.DATE)
        : '';
    let toValue =
      typeof to === 'string' && to.length > 0
        ? dayjs(to, DATE_FORMATS.DATE_DOTS).format(DATE_FORMATS.DATE)
        : '';

    if (useStubTime && fromValue && toValue) {
      fromValue = `${fromValue}${timeGTEStub}`;
      toValue = `${toValue}${timeLTEStub}`;
    }

    if (typeof saveDate === 'function') {
      saveDate({ gte: fromValue, lte: toValue });
    }
    if (handleForm && queryName) {
      handleForm.change(queryName.gte, fromValue);
      handleForm.change(queryName.lte, toValue);
    }

    onChange(event, fromValue, toValue);
  };

  handleOnOpenChange = () => {
    this.toggleDatePicker();
  };

  hideDatePicker = () => {
    this.setState({
      isOpen: false,
    });
  };

  toggleDatePicker = () => {
    this.setState(({ isOpen }) => ({
      isOpen: !isOpen,
    }));
  };

  mobileFooter = () => {
    const { isMobile } = this.props;

    if (isMobile) {
      return (
        <Styled.Close>
          <Button type="button" onlySmallIcon transparent onClick={this.hideDatePicker}>
            <CloseIcon />
          </Button>
        </Styled.Close>
      );
    }

    return null;
  };

  render() {
    const { value, size, placeholder, withoutOutdated, withMobile, isMobile } = this.props;
    const from = isString(head(value)) ? dayjs(head(value)) : head(value);
    const to = isString(last(value)) ? dayjs(last(value)) : last(value);
    const initialFrom = from ? dayjs(from, DATE_FORMATS.DATE_TIME) : undefined;
    const initialTo = to ? dayjs(to, DATE_FORMATS.DATE_TIME) : undefined;

    const presets = [
      { label: 'Сегодня', value: [dayjs(), dayjs()] },
      {
        label: 'Вчера',
        value: [dayjs().subtract(1, 'day').startOf('day'), dayjs().subtract(1, 'day').endOf('day')],
      },
      { label: 'Текущая неделя', value: [dayjs().startOf('week'), dayjs().endOf('week')] },
      {
        label: 'Прошлая неделя',
        value: [dayjs().subtract(1, 'week').startOf('week'), dayjs().subtract(1, 'week').endOf('week')],
      },
      { label: 'Текущий месяц', value: [dayjs().startOf('month'), dayjs().endOf('month')] },
      {
        label: 'Прошлый месяц',
        value: [dayjs().subtract(1, 'month').startOf('month'), dayjs().subtract(1, 'month').endOf('month')],
      },
      { label: 'Текущий год', value: [dayjs().startOf('year'), dayjs().endOf('year')] },
    ];

    return (
      <Styled.Container>
        {placeholder && (
          <Label isOpen={this.state.isOpen} withIcon value={initialFrom || initialTo}>
            {placeholder}
          </Label>
        )}

        <RangePicker
          className={classnames({
            'range-date-picker': true,
            'range-date-picker--with-mobile': withMobile,
            'range-date-picker__without-value': !(initialFrom && initialTo),
          })}
          open={this.state.isOpen}
          onChange={this.handleChange}
          placeholder={['', '']}
          showTime={false}
          locale={locale}
          placement="topLeft"
          format={DATE_FORMATS.DATE_DOTS}
          disabledDate={withoutOutdated ? disabledDate : undefined}
          getPopupContainer={isMobile ? () => this.pickerContainer : undefined}
          presets={presets}
          value={[initialFrom, initialTo]}
          onOpenChange={this.handleOnOpenChange}
          size={size}
          separator="-"
          renderExtraFooter={this.mobileFooter}
          onKeyDown={(e) => {
            if (
              !/[\d/-]/.test(e.key) &&
              e.key !== 'Backspace' &&
              e.key !== 'Delete' &&
              !e.ctrlKey &&
              !e.metaKey
            ) {
              e.preventDefault();
            }
          }}
        />
        {withMobile && (
          <Styled.Calendar>
            <Button onlyIcon transparent type="button" onClick={this.toggleDatePicker}>
              <CalendarIcon />
            </Button>
          </Styled.Calendar>
        )}
      </Styled.Container>
    );
  }
}

export default withCheckIsMobile(RangeDateTimePicker);
