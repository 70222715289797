import { i18n } from 'next-i18next';
import { IBasicEventListItem } from '../../api/events';
import { EventResponse } from '../../api/market/events';
import { LANGUAGES } from '../../constants/LANGUAGES';
import { isPasteDate } from '../date';

export const getAdaptedFilterEvent = (event: IBasicEventListItem, onlyActiveDates = false) => {
  const lang = i18n?.language || LANGUAGES.RU;
  const placeInfo = event?.place?.info?.find((i) => i.language_code === lang);

  return {
    ...event,
    title: event?.title,
    place: {
      id: event?.place?.id,
      name: placeInfo?.title || event?.place?.title,
    },
    children: event?.dates
      ?.filter((date) => (onlyActiveDates ? !isPasteDate(date.finished_at) : true))
      .map((date) => {
        const [date_start, time_start] = date.started_at.split('T');
        const [date_finish, time_finish] = date.finished_at.split('T');
        return {
          id: date.id,
          parent_id: date.event_id,
          date_start,
          time_start,
          date_finish,
          time_finish,
        };
      }),
  };
};

export const getAdaptedMarketEvent = (event: EventResponse, onlyActiveDates = false) => {
  const lang = i18n?.language || LANGUAGES.RU;
  const info = event.info?.find((i) => i.language_code === lang);
  const placeInfo = event?.event?.place?.info?.find((i) => i.language_code === lang);

  return {
    ...event,
    title: info?.title,
    place: {
      id: event?.event.place?.id,
      name: placeInfo?.title || event?.place?.title,
    },
    children: event?.event.dates
      .filter((date) => (onlyActiveDates ? !isPasteDate(date.finished_at) : true))
      .map((date) => {
        const [date_start, time_start] = date.started_at.split('T');
        const [date_finish, time_finish] = date.finished_at.split('T');
        return {
          id: date.id,
          parent_id: date.event_id,
          date_start,
          time_start,
          date_finish,
          time_finish,
        };
      }),
  };
};
