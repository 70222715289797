import styled, { createGlobalStyle } from 'styled-components';
import BREAKPOINTS from 'shared/constants/BREAKPOINTS';
import COLORS from 'shared/constants/COLORS';
import { getBorderOnError } from 'shared/helpers/input';

interface ContainerProps {
  highlighted?: boolean;
  meta: any;
}

export default {
  Container: styled.div<ContainerProps>`
    position: relative;
    width: 100%;

    .ant-input {
      border-color: ${(props) => (props.highlighted ? COLORS.YELLOW : null)};
      ${getBorderOnError};
      width: 100%;
    }
  `,

  Outdated: styled.button`
    width: 100%;
    background: none;
    border: 0;
    color: #1890ff;
    text-align: center;
    cursor: pointer;
    outline: none;
  `,

  Overlay: styled.div`
    position: fixed;
    z-index: 1000000;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  `,

  MobileClose: styled.button`
    z-index: 2;
    position: fixed;
    top: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    border: none;
    background: none;
    opacity: 0.4;
    transition: 0.3s ease-in-out;

    &:hover,
    &:focus {
      opacity: 1;
    }
  `,

  MobileGlobalStyles: createGlobalStyle`
    @media (max-width: ${BREAKPOINTS.SM}) {
      .custom-datepicker-popup {
        inset: none !important;
        .ant-picker-panel-container {
          position: fixed;
          top: 0 !important;
          left: 0 !important;
          right: 0 !important;
          bottom: 0 !important;
        }

        .ant-picker-panel-layout {
          width: 100%;
          height: 100%;

          & > div {
            display: flex;
            flex-direction: column;
            width: 100%;
          }
        }

        .ant-picker-panel {
          flex-grow: 1;
        }

        .ant-picker-date-panel {
          width: 100% !important;
        }
      }
    }
  `,
};
