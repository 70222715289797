import moment from 'moment';
import DATE_FORMATS from 'shared/constants/DATE_FORMATS';

function formatDayOfWeek(date) {
  return moment(date).format(DATE_FORMATS.DAY);
}

function formatFullDate(date) {
  return moment(date, DATE_FORMATS.DATE_TIME).format(DATE_FORMATS.DATE_TIME_PREVIEW);
}

function formatDotsDate(date) {
  return moment(date, DATE_FORMATS.DATE).format(DATE_FORMATS.DATE_DOTS);
}

function formatDotsDateShort(date) {
  return moment(date, DATE_FORMATS.DATE).format(DATE_FORMATS.DATE_DOTS_SHORT);
}

function formatDashDate(date) {
  return moment(date, DATE_FORMATS.DATE_TIME).format(DATE_FORMATS.DATE);
}

function formatDateMonthString(date) {
  return moment(date, DATE_FORMATS.DATE_TIME).format(DATE_FORMATS.DATE_PREVIEW);
}

function formatTime(date) {
  return moment(date, DATE_FORMATS.DATE_TIME).format(DATE_FORMATS.TIME);
}

function formatTimeFromDate(date) {
  return moment(date, DATE_FORMATS.DATE_TIME).format(DATE_FORMATS.TIME_PREVIEW);
}

function formatTimeByTime(time) {
  return moment(time, DATE_FORMATS.TIME).format(DATE_FORMATS.TIME_PREVIEW);
}

function formatEventDateTime(date, time) {
  return {
    date: moment(date, DATE_FORMATS.DATE).format(DATE_FORMATS.DATE_PREVIEW),
    time: moment(time, DATE_FORMATS.TIME).format(DATE_FORMATS.TIME_PREVIEW),
  };
}

function formatRawDateTime(datetime, format?: string) {
  if (moment(datetime).format('YYYY') === moment().format('YYYY')) {
    return moment(datetime)
      .format(format || DATE_FORMATS.DATE_PREVIEW)
      .replace('.', '')
      .replace(` ${moment().format('YYYY')}`, '');
  }
  return moment(datetime)
    .format(format || DATE_FORMATS.DATE_PREVIEW)
    .replace('.', '');
}

function formatFullDateToMonthAndTime(date) {
  return moment(date, DATE_FORMATS.DATE_TIME).format(DATE_FORMATS.MONTH_TIME_PREVIEW);
}

function formatFullDateAndTime(date) {
  if (moment(date).format('YYYY') === moment().format('YYYY')) {
    return moment(date, DATE_FORMATS.DATE_TIME)
      .format(DATE_FORMATS.DATE_TIME_FULL)
      .replace('.', '')
      .replace(` ${moment().format('YYYY')}`, '');
  }
  return moment(date, DATE_FORMATS.DATE_TIME).format(DATE_FORMATS.DATE_TIME_FULL).replace('.', '');
}

function formatSeconds(seconds) {
  return moment.utc(seconds * 1000).format('HH:mm:ss');
}

function formatDateToDayPreview(date) {
  return moment(date, DATE_FORMATS.DATE).format(DATE_FORMATS.MONTH_REVIEW);
}

function formatDateToFullDatePreview(date) {
  return moment(date, DATE_FORMATS.DATE).format(DATE_FORMATS.DATE_PREVIEW);
}

function formatDateFromNow(date) {
  const nowDate = moment.utc();
  const receivedDate = moment.utc(date);

  const days = nowDate.startOf('days').diff(receivedDate.startOf('days'), 'days');

  if (days < 1) {
    return `Сегодня, ${formatTimeFromDate(date)}`;
  }

  if (days < 2) {
    return `Вчера, ${formatTimeFromDate(date)}`;
  }

  return formatFullDateAndTime(date);
}

export {
  formatTimeFromDate,
  formatTimeByTime,
  formatTime,
  formatFullDate,
  formatDotsDate,
  formatDashDate,
  formatDateMonthString,
  formatEventDateTime,
  formatRawDateTime,
  formatFullDateToMonthAndTime,
  formatDateToDayPreview,
  formatFullDateAndTime,
  formatDateFromNow,
  formatDayOfWeek,
  formatDateToFullDatePreview,
  formatSeconds,
  formatDotsDateShort,
};
