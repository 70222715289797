import styled, { createGlobalStyle } from 'styled-components';
import BREAKPOINTS from 'shared/constants/BREAKPOINTS';
import { getBorderOnError } from 'shared/helpers/input';

export default {
  Overlay: styled.div`
    position: fixed;
    z-index: 1000;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  `,

  Container: styled.div<any>`
    position: relative;

    .ant-picker {
      width: 100%;
    }

    .ant-picker {
      ${getBorderOnError};
      height: 40px;
      box-sizing: border-box;
      border-radius: 8px;
    }
  `,

  MobileGlobalStyles: createGlobalStyle`
    @media (max-width: ${BREAKPOINTS.SM}) {
      .custom-time-picker-popup {
        inset: none !important;
        .ant-picker-panel-container {
          position: fixed;
          top: 0 !important;
          left: 0 !important;
          right: 0 !important;
          bottom: 0 !important;
        }

        .ant-picker-panel-layout {
          width: 100%;
          height: 100%;

          & > div {
            display: flex;
            flex-direction: column;
            width: 100%;
          }
        }

        .ant-picker-panel {
          flex-grow: 1;
        }

        .ant-picker-time-panel {
          height: 100%;
        }
        
        .ant-picker-time-panel-column {
          overflow-y: auto !important;
        }
      }
    }
  `,
};
