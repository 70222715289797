import { message } from 'antd';
import { AxiosError } from 'axios';
import { createEffect } from 'effector';
import { NotificationManager } from 'react-notifications';

export const showedErrorNotification = createEffect((message?: string | AxiosError) => {
  const text = typeof message === 'string' ? message : undefined;
  NotificationManager.error(text, 'Ошибка');
});

export const showedSuccesNotification = createEffect((message?: string) => {
  const text = typeof message === 'string' ? message : 'Успешно';
  NotificationManager.success(text);
});

export const showedErrorNotificationFx = createEffect((content?: string | null) => {
  message.error(content || 'Ошибка');
});
export const showedSuccesNotificationFx = createEffect((content?: string | null) => {
  message.success(content || 'Успешно');
});
export const showedInfoNotificationFx = createEffect((content?: string | null) => {
  message.info(content);
});
