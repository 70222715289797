import { AxiosError, AxiosResponse } from 'axios';
import config from 'config/config';
import { createEffect } from 'effector';
import { isEmpty } from 'lodash';
import { i18n } from 'next-i18next';
import { PagePaginationQuery } from '../../factory';
import api from '../../services/api';
import { TranslatedInfo } from '../types';

const route = `${config.COMMON_API_URL}/admin/v1/locations`;

interface UpdateLocationFxProps {
  id: number;
  translation: { slug: string; name: string; country_id: { value: number }; code: string };
}
interface UpdateLocationInfoFxProps {
  id: number;
  translation: { language_code: string; name: string };
}

interface CreateLocationInfoFx {
  id: number;
  translation: { language_code: string; name: string };
}

export default {
  root: `${config.COMMON_API_URL}/admin/v1/locations`,
  detail: (id: number) => `${config.COMMON_API_URL}/admin/v1/locations/${id}`,
};

export const fetchLocationsFx = createEffect(async (query: PagePaginationQuery) => {
  const { country, search, ...rest } = query;
  const response = await api.get(`${config.COMMON_API_URL}/admin/v1/locations`, {
    params: {
      ...rest,
      ...(country && { country_id_in: country }),
      search_string: search,
      language_code: i18n?.language,
      limit: 250,
    },
  });

  return response.data;
});

export const fetchLocationFx = createEffect(async (location_id: number) => {
  const response = await api.get(`${route}/${location_id}`);

  return response.data;
});

export const updateLocationFx = createEffect(async ({ id, translation }: UpdateLocationFxProps) => {
  const response = await api.patch(`${route}/${id}`, {
    slug: translation.slug,
    code: translation.code,
    country_id: translation?.country_id?.value,
  });

  return response;
});

export const updateSubLocationFx = createEffect(async ({ citiesItems, dataRu, deletedLocations }: any) => {
  const promises = [];
  if (!isEmpty(deletedLocations)) {
    for (const deletedItem of deletedLocations) {
      promises.push(api.patch(`${route}/${deletedItem.id}`, { location_group_id: null }));
    }
    await Promise.all(promises);
  }

  for (const item of citiesItems) {
    promises.push(api.patch(`${route}/${item.id}`, { location_group_id: dataRu.id }));
  }
  const responses = await Promise.all(promises);

  return responses;
});

export const updateLocationInfoFx = createEffect<
  UpdateLocationInfoFxProps,
  AxiosResponse<TranslatedInfo>,
  AxiosError
>(async ({ id, translation }) => {
  const response = await api.patch(`${route}/${id}/info/${translation?.language_code}`, {
    title: translation.name,
  });

  return response;
});

export const createLocationInfoFx = createEffect<
  CreateLocationInfoFx,
  AxiosResponse<TranslatedInfo>,
  AxiosError
>(async ({ id, translation }) => {
  const response = await api.post(`${route}/${id}/info`, {
    title: translation.name,
    language_code: translation?.language_code,
  });

  return response;
});

export const deleteLocationFx = createEffect<string | number, AxiosResponse<undefined>, AxiosError>(
  async (id) => {
    const response = await api.delete(`${route}/${id}`);

    return response;
  },
);

export const createLocationFx = createEffect(async (data: any) => {
  const response = await api.post(`${route}`, {
    slug: data.dataRu.slug,
    code: data.dataRu.code,
    country_id: data?.dataRu.country_id?.value,
  });

  return response;
});

export const createLocationInfosFx = createEffect(
  async ({ id, translations }: { id: string; translations: [] }) => {
    const requests = translations.map(async (translation: object) => {
      const response = await api.post(`${route}/${id}/info`, translation);

      return response;
    });

    await Promise.all(requests);
  },
);

export { route as locationsRoute };
