import axios from 'axios';
import get from 'lodash/get';
import { NotificationManager } from 'react-notifications';
import config from 'config/config';
import COOKIES from 'shared/constants/COOKIES';
import { getToken } from 'shared/lib/auth';
import { getCookie, setCookie } from 'shared/lib/cookie';
import { i18n } from '../i18n';

const api = axios.create({
  baseURL: config.API_URL,
  responseType: 'json',
});

api.interceptors.request.use(
  (axiosConfig) => {
    const token = getToken();

    if (axiosConfig.headers.Authorization && !token) {
      return axiosConfig;
    }

    const lngConfig = get(axiosConfig, 'lng');
    let { url } = axiosConfig;

    if (url && lngConfig) {
      url =
        url.indexOf('?') !== -1
          ? url.replace('?', `/translation/${lngConfig}?`)
          : `${url}/translation/${lngConfig}`;
    }

    const isCommonApi = url ? url.includes(config.COMMON_API_URL) : null;

    return {
      ...axiosConfig,
      url,
      headers: {
        ...(token && { Authorization: `Bearer ${token}` }),
        ...axiosConfig.headers,
        ...(token && !isCommonApi && { 'internal-auth-token': `Token ${config.INTERNAL_AUTH_TOKEN}` }),
        'Accept-Language':
          axiosConfig.headers['Accept-Language'] ||
          get(axiosConfig, 'lng') ||
          get(axiosConfig, 'reduxSourceAction.payload.lng', i18n?.language || 'ru'),
      },
    };
  },
  (error) => Promise.reject(error),
);

api.interceptors.response.use(
  (response: any) => {
    if (response.headers['x-refresh-access-token']) {
      const newToken = response.headers['x-refresh-access-token'];
      setCookie(COOKIES.TOKEN, newToken);
    }

    if (response.config.reduxSourceAction && response.config.reduxSourceAction.payload.withNotification) {
      NotificationManager.success('Успешно');
    }

    return response;
  },
  (error) => {
    if (error.message === 'Network Error' && !error.config?.notificationHiddenOnError) {
      NotificationManager.error('Ошибка сети');
    }

    return Promise.reject(error);
  },
);

export default api;
