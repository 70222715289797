const PAYMENTS = {
  CASH: 1,
  CARD: 2,
  ACQUIRING: 3,
  ACCOUNT: 4,
};

export const PAYMENT_LABELS = {
  [PAYMENTS.CASH]: 'Наличными',
  [PAYMENTS.CARD]: 'Перевод на карту',
  [PAYMENTS.ACQUIRING]: 'Эквайринг',
  [PAYMENTS.ACCOUNT]: 'Расчёстный счёт',
};

export const PAYMENTS_OPTIONS = [
  /* { id: PAYMENTS.CARD, value: PAYMENTS.CARD, name: 'Перевод на карту' }, */
  { id: PAYMENTS.ACQUIRING, value: PAYMENTS.ACQUIRING, name: 'Эквайринг' },
  /* { id: PAYMENTS.ACCOUNT, value: PAYMENTS.ACCOUNT, name: 'Расчетный счет' }, */
];

export const PAYMENT_PROVIDERS = {
  SBERBANK: 1,
  TINKOFF: 2,
  CLOUDPAYMENTS: 3,
  CHECKOUT: 4,
  CIRCLE: 5,
  CHECKOUT_HOSTED: 6,
  STRIPE: 7,
  PAYSELECTION: 8,
  PAYME: 9,
  STRIPE_SECOND: 10,
  TELR: 11,
  ZIINA: 12,
};

export const PAYMENT_PROVIDERS_LABELS = {
  [PAYMENT_PROVIDERS.SBERBANK]: 'Сбербанк',
  [PAYMENT_PROVIDERS.TINKOFF]: 'Тинькофф',
  [PAYMENT_PROVIDERS.CLOUDPAYMENTS]: 'CloudPayments',
  [PAYMENT_PROVIDERS.CHECKOUT]: 'Checkout',
  [PAYMENT_PROVIDERS.CIRCLE]: 'Circle',
  [PAYMENT_PROVIDERS.CHECKOUT_HOSTED]: 'Checkout Hosted Payments Page',
  [PAYMENT_PROVIDERS.STRIPE]: 'Stripe',
  [PAYMENT_PROVIDERS.PAYSELECTION]: 'Payselection',
  [PAYMENT_PROVIDERS.PAYME]: 'Payme',
  [PAYMENT_PROVIDERS.STRIPE_SECOND]: 'Stripe V20240620',
  [PAYMENT_PROVIDERS.TELR]: 'Telr',
  [PAYMENT_PROVIDERS.ZIINA]: 'ziina',
};

export const PAYMENT_TAXATION_SYSTEM = [
  { value: 1, label: 'Oбщая СН' },
  { value: 2, label: 'Упрощенная СН' },
  { value: 3, label: 'Упрощенная СН (доходы минус расходы)' },
  { value: 4, label: 'Единый налог на вмененный доход' },
  { value: 5, label: 'Единый сельскохозяйственный налог' },
  { value: 6, label: 'Патентная СН' },
];

export const PAYMENT_FFD_VERSION = [
  { value: 1, label: '1.2' },
  { value: 2, label: '1.05' },
];

export const PAYMENT_PROVIDERS_OPTIONS = [
  { id: PAYMENT_PROVIDERS.SBERBANK, value: PAYMENT_PROVIDERS.SBERBANK, name: 'Сбербанк' },
  { id: PAYMENT_PROVIDERS.TINKOFF, value: PAYMENT_PROVIDERS.TINKOFF, name: 'Тинькофф' },
  { id: PAYMENT_PROVIDERS.CLOUDPAYMENTS, value: PAYMENT_PROVIDERS.CLOUDPAYMENTS, name: 'CloudPayments' },
  { id: PAYMENT_PROVIDERS.CHECKOUT, value: PAYMENT_PROVIDERS.CHECKOUT, name: 'Checkout' },
  {
    id: PAYMENT_PROVIDERS.CHECKOUT_HOSTED,
    value: PAYMENT_PROVIDERS.CHECKOUT_HOSTED,
    name: 'Checkout Hosted Payments Page',
  },
  { id: PAYMENT_PROVIDERS.CIRCLE, value: PAYMENT_PROVIDERS.CIRCLE, name: 'Circle' },
  { id: PAYMENT_PROVIDERS.STRIPE, value: PAYMENT_PROVIDERS.STRIPE, name: 'Stripe' },
  { id: PAYMENT_PROVIDERS.PAYSELECTION, value: PAYMENT_PROVIDERS.PAYSELECTION, name: 'Payselection' },
  { id: PAYMENT_PROVIDERS.PAYME, value: PAYMENT_PROVIDERS.PAYME, name: 'Payme' },
  { id: PAYMENT_PROVIDERS.STRIPE_SECOND, value: PAYMENT_PROVIDERS.STRIPE_SECOND, name: 'Stripe V20240620' },
  { id: PAYMENT_PROVIDERS.TELR, value: PAYMENT_PROVIDERS.TELR, name: 'Telr' },
  { id: PAYMENT_PROVIDERS.ZIINA, value: PAYMENT_PROVIDERS.ZIINA, name: 'ziina' },
];

export const PAYMENT_AREA_OPTIONS = [
  { id: 1, value: 1, name: 'Только Россия' },
  { id: 2, value: 2, name: 'Кроме России' },
  { id: 3, value: 3, name: ' Весь мир' },
];

export const PAYMENT_AREA_LABELS = {
  1: 'Только Россия',
  2: 'Кроме России',
  3: 'Весь мир',
};

export default PAYMENTS;
